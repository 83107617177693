import { ConsentType } from "@gocardless/api/dashboard/types";
import { ReactNode } from "react";
import { Scheme } from "src/common/scheme";

type BaseDisplayedConsentInformationType = {
  [key in ConsentType]: {
    displayedConsentType?: ReactNode;
    toolTipContent: ReactNode;
  };
};

export type SchemesWithConsentParameters = Scheme.Ach | Scheme.Pad;

type DisplayedConsentInformationType = {
  [key in SchemesWithConsentParameters]: BaseDisplayedConsentInformationType;
};

// TODO: Add translations

const baseDisplayedConsentInformation: BaseDisplayedConsentInformationType = {
  [ConsentType.OneOff]: {
    displayedConsentType: <>One-off</>,
    toolTipContent: (
      <>
        A one-off ACH Debit Authorization is a legal agreement that allows a
        business or organization to withdraw a single payment for the amount
        agreed
      </>
    ),
  },
  [ConsentType.Recurring]: {
    displayedConsentType: <>Recurring</>,
    toolTipContent: (
      <>
        A recurring ACH Debit Authorization is a legal agreement that allows a
        business or organization to withdraw a funds from a customer’s bank
        account in accordance with the agreed amount and timings
      </>
    ),
  },
  [ConsentType.Single]: {
    displayedConsentType: <>Single</>,
    toolTipContent: (
      <>
        A one-off Pre-Authorized Debit Agreement is a legal agreement that
        allows a business or organization to withdraw a single payment for the
        amount agreed
      </>
    ),
  },
  [ConsentType.Sporadic]: {
    displayedConsentType: <>Sporadic</>,
    toolTipContent: (
      <>
        A Sporadic Pre-Authorized Debit is a legal agreement that allows a
        business or organization to withdraw funds directly from a customer’s
        bank account
      </>
    ),
  },
  [ConsentType.Standing]: {
    displayedConsentType: <>Standing</>,
    toolTipContent: (
      <>
        A standing ACH Debit Authorization is a legal agreement that allows a
        business or organization to withdraw funds directly from a customer’s
        bank account
      </>
    ),
  },
};

export const DisplayedConsentInformation: DisplayedConsentInformationType = {
  [Scheme.Ach]: { ...baseDisplayedConsentInformation },
  [Scheme.Pad]: {
    ...baseDisplayedConsentInformation,
    [ConsentType.Recurring]: {
      displayedConsentType: <>Recurring</>,
      toolTipContent: (
        <>
          A recurring Pre-Authorized Debit Agreement is a legal agreement that
          allows a business or organization to withdraw a funds from a
          customer’s bank account in accordance with the agreed amount and
          timings
        </>
      ),
    },
  },
};
