import {
  Button,
  Space,
  ButtonVariant,
  Icon,
  Glyph,
  Color,
  FontWeight,
  Text,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { ExpandedStates } from "src/common/states";

export const ToggleButton = ({
  showDetails,
  setNextState,
  currentState,
}: {
  showDetails: boolean;
  setNextState: () => void;
  currentState: ExpandedStates;
}) => {
  // using display css property in the toggle button text
  // because of the time it takes to initially render the Chevron icons
  return (
    <>
      <Button
        variant={ButtonVariant.Inline}
        onClick={setNextState}
        aria-controls="payment-agreement-show-more"
        aria-expanded={currentState === ExpandedStates.Expanded}
      >
        {/* if consent params more details true */}
        <Text
          size={2}
          color={Color.Greystone_1400}
          weight={FontWeight.SemiBold}
          style={{ display: showDetails ? "none" : "block" }}
        >
          <Trans id="billing-request-description.payment-agreement-description.show-details">
            Show details
          </Trans>
          <Space h={0.5} layout="inline" />
          <Icon name={Glyph.ChevronDown} size="10px" />
        </Text>
        {/* if not consent params more details true */}
        <Text
          size={2}
          color={Color.Greystone_1400}
          weight={FontWeight.SemiBold}
          style={{ display: showDetails ? "block" : "none" }}
        >
          <Trans id="billing-request-description.payment-agreement-description.hide-details">
            Hide details
          </Trans>
          <Space h={0.5} layout="inline" />
          <Icon name={Glyph.ChevronUp} size="10px" />
        </Text>
      </Button>
    </>
  );
};
