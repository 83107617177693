import {
  DisplayedConsentInformation,
  SchemesWithConsentParameters,
} from "src/components/shared/BillingRequestDescription/AuthorisationDetails/DisplayedConsentInformation";
import {
  Box,
  ColorPreset,
  FontWeight,
  JustifyContent,
  Text,
  Tooltip,
  TypePreset,
} from "@gocardless/flux-react";
import { ConsentType as ConsentTypes } from "@gocardless/api/dashboard/types";

interface ConsentTypeProps {
  consentType: ConsentTypes;
  scheme: SchemesWithConsentParameters;
}

export const ConsentType = ({ consentType, scheme }: ConsentTypeProps) => {
  const { displayedConsentType, toolTipContent } =
    DisplayedConsentInformation[scheme][consentType];

  return (
    <Box layout="flex" spaceAbove={1}>
      <Box width="100%">
        <Text color={ColorPreset.TextOnLight_01} preset={TypePreset.Body_01}>
          <>Consent type</>
        </Text>
      </Box>
      <Box
        width="100%"
        layout="inline-flex"
        justifyContent={JustifyContent.End}
      >
        <Text
          color={ColorPreset.TextOnLight_01}
          weight={FontWeight.SemiBold}
          preset={TypePreset.Body_01}
        >
          {displayedConsentType}
        </Text>
        <Tooltip message={toolTipContent} positionStrategy="absolute">
          Trigger the tooltip
        </Tooltip>
      </Box>
    </Box>
  );
};
