import { ReactElement, useCallback, useContext, useState } from "react";
import { useToggle } from "react-use";
import {
  AlignItems,
  Box,
  Color,
  FontWeight,
  P,
  Tag,
  TagSize,
  TagVariant,
  Text,
  Button,
  ButtonVariant,
  JustifyContent,
  Visibility,
  XYGrid,
  Space,
  Hint,
  FormFieldStatus,
  useTheme,
  ColorPreset,
  ButtonSize,
  Glyph,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import Amount from "src/legacy-ui/amount";
import {
  ActionType,
  AvailableDebitSchemeEnum,
  BillingRequestFlowResource,
  BillingRequestResource,
  Currency,
  InstalmentTemplateResource,
  PaylinkResource,
  PlanResource,
} from "@gocardless/api/dashboard/types";
import { billingRequestChooseCurrency } from "@gocardless/api/dashboard/billing-request";
import { translateForScheme } from "src/common/scheme-translations/translateForScheme";
import {
  BrandedComponentType,
  getBrandColorFor,
  getPayerName,
  isBankRecurringFlow,
  isDualFlow,
  isMandateOnlyFlow,
  leadBillingRequestScheme,
  showError,
} from "src/common/utils";
import { getPaymentVariant, PaymentVariant } from "src/common/payments";
import { GlobalState } from "src/state";
import { HTTPError } from "@gocardless/api/utils/api";
import { HTTPStatusCode } from "@gocardless/api/http/http-status-codes";
import { ErrorsEnum } from "src/state/errors";
import { getErrorsFromErrorResponse } from "@gocardless/api/utils/error";
import { CountryCodes } from "src/common/country";
import { customerDetailsForSchemeAndCountry } from "src/config/customer-details/customer-details-config";
import { useRouter } from "next/router";
import { ExpandedStates } from "src/common/states";
import { AuthorisationDetails } from "src/components/shared/BillingRequestDescription/AuthorisationDetails";

import { findAction, Routes } from "../Router";
import CurrencySelector from "../CurrencySelector";
import BrandedButton from "../BrandedComponents/BrandedButton";

import PlanDescription from "./PlanDescription";
import InstalmentTemplateDescription from "./InstalmentTemplateDescription";
import PaymentAgreementDescription from "./PaymentAgreementDescription";

export interface BillingRequestDescriptionProps {
  billingRequest: BillingRequestResource;
  billingRequestFlow?: BillingRequestFlowResource;
  paylink: PaylinkResource | undefined;
  mandateRequestExtendedConfirmation?: boolean;
  plan?: PlanResource;
  instalmentTemplate?: InstalmentTemplateResource;
  page?: Routes;
}

// This should render a description that summarises the contents in the Billing
// Request. If we're setting up a mandate, that should be made clear — likewise
// with any payment requests, or other resources that we support against Billing
// Requests in the future.
const BillingRequestDescription = ({
  billingRequest,
  billingRequestFlow,
  mandateRequestExtendedConfirmation,
  plan,
  instalmentTemplate,
  paylink,
  page,
}: BillingRequestDescriptionProps) => {
  const paymentVariant = getPaymentVariant(billingRequest, paylink);
  const isInstantPay =
    paymentVariant === PaymentVariant.InstantBankPay ||
    paymentVariant === PaymentVariant.DualFlow ||
    paymentVariant === PaymentVariant.VariableRecurringPaymentsWithFirstPayment;
  const instantBankPayCurrency = billingRequest?.payment_request?.currency;
  const instantBankPayAmount = parseInt(
    billingRequest?.payment_request?.amount?.toString() ?? "0"
  );
  const directDebitCurrency = paylink?.currency;
  const directDebitAmount = parseInt(paylink?.amount?.toString() ?? "0");
  const description = isInstantPay
    ? billingRequest.payment_request?.description
    : paylink?.name;
  const leadScheme = leadBillingRequestScheme(billingRequest);

  if (instalmentTemplate) {
    return (
      <InstalmentTemplateDescription
        instalmentTemplate={instalmentTemplate}
        billingRequest={billingRequest}
      />
    );
  }
  if (plan) {
    return <PlanDescription billingRequest={billingRequest} plan={plan} />;
  }

  if (
    isBankRecurringFlow(billingRequest) &&
    (isMandateOnlyFlow(billingRequest) || isDualFlow(billingRequest))
  ) {
    return (
      <PaymentAgreementDescription
        defaultExpanded={
          page === Routes.CollectBankAccount
            ? ExpandedStates.Collapsed
            : ExpandedStates.Default
        }
        schemeLogo={translateForScheme({
          scheme: leadScheme as AvailableDebitSchemeEnum,
          translationKey: "billing-request-description-info-scheme-logo",
          params: {},
        })}
        billingRequest={billingRequest}
      />
    );
  }

  switch (paymentVariant) {
    case PaymentVariant.InstantBankPay:
      return (
        <PaymentDescription
          isAmountFlexible={billingRequest.payment_request?.flexible_amount}
          paymentVariant={paymentVariant}
          currency={instantBankPayCurrency}
          amountInPence={instantBankPayAmount}
          description={description}
          page={page}
        />
      );
    case PaymentVariant.DualFlow:
    case PaymentVariant.VariableRecurringPaymentsWithFirstPayment:
      return (
        <>
          <PaymentDescription
            isAmountFlexible={billingRequest.payment_request?.flexible_amount}
            paymentVariant={paymentVariant}
            currency={instantBankPayCurrency}
            amountInPence={instantBankPayAmount}
            description={description}
            page={page}
          />
          <MandateDescription
            paymentVariant={paymentVariant}
            billingRequest={billingRequest}
            billingRequestFlow={billingRequestFlow}
            extendedConfirmation={mandateRequestExtendedConfirmation}
            page={page}
          />
        </>
      );
    case PaymentVariant.DirectDebitOneOff:
      return (
        <PaymentDescription
          paymentVariant={paymentVariant}
          currency={directDebitCurrency}
          amountInPence={directDebitAmount}
          schemeLogo={translateForScheme({
            scheme: billingRequest?.mandate_request
              ?.scheme as AvailableDebitSchemeEnum,
            translationKey: "billing-request-description-info-scheme-logo",
            params: {},
          })}
          description={description}
        />
      );
    case PaymentVariant.DirectDebitRestrictedMandate:
      return (
        <>
          <PaymentDescription
            paymentVariant={paymentVariant}
            currency={directDebitCurrency}
            amountInPence={directDebitAmount}
            description={description}
          />
          <MandateDescription
            paymentVariant={paymentVariant}
            billingRequest={billingRequest}
            billingRequestFlow={billingRequestFlow}
            extendedConfirmation={mandateRequestExtendedConfirmation}
            page={page}
          />
        </>
      );
    case PaymentVariant.DirectDebitMandate:
    case PaymentVariant.VerifiedMandate:
      return (
        <MandateDescription
          paymentVariant={paymentVariant}
          billingRequest={billingRequest}
          billingRequestFlow={billingRequestFlow}
          extendedConfirmation={mandateRequestExtendedConfirmation}
          page={page}
        />
      );

    default:
      return null;
  }
};

interface PaymentDescriptionProps {
  paymentVariant: PaymentVariant | null;
  currency: string | undefined;
  amountInPence: number | undefined;
  schemeLogo?: ReactElement | null;
  description: string | null | undefined;
  isAmountFlexible?: boolean;
  page?: Routes;
}

const PaymentDescription = ({
  paymentVariant,
  currency = "GBP",
  amountInPence = 0,
  schemeLogo,
  description,
  isAmountFlexible = false,
  page,
}: PaymentDescriptionProps) => {
  const { theme } = useTheme();
  const { push, payerTheme, setIsChangeAmountLinkClicked } =
    useContext(GlobalState);

  return (
    <XYGrid rowGap={0.25}>
      <P weight={FontWeight.SemiBold}>{description}</P>
      <XYGrid
        templateColumns={
          paymentVariant === PaymentVariant.DirectDebitOneOff
            ? "auto auto auto 1fr"
            : "auto auto 1fr"
        }
        columnGap={0.5}
        alignItems={AlignItems.Baseline}
        justifyContent={JustifyContent.Start}
      >
        <Amount currency={currency} amountInPence={amountInPence} />
        <Tag
          size={TagSize.Sm}
          variant={TagVariant.Tinted}
          css={{
            backgroundColor: theme.color(Color.Greystone_300),
            color: theme.color(ColorPreset.TextOnLight_01),
            fontWeight: FontWeight.Normal,
          }}
        >
          <Trans id="billing-request-description.payment-description.one-off-payment">
            One-off payment
          </Trans>
        </Tag>
        {isAmountFlexible && page !== Routes.CollectAmount && (
          <Box css={{ justifySelf: "end" }}>
            <BrandedButton
              textColor={getBrandColorFor(
                BrandedComponentType.Link,
                payerTheme
              )}
              variant={ButtonVariant.Inline}
              onClick={() => {
                setIsChangeAmountLinkClicked(true);
                push(Routes.CollectAmount, {
                  origin: "BillingRequestDescription",
                  reason: "clicked change amount link",
                });
              }}
              size={ButtonSize.Sm}
              leftIcon={Glyph.Edit}
              id="changeCustomer"
            >
              <Trans id="confirm-details-page.form.change-button">Change</Trans>
            </BrandedButton>
          </Box>
        )}
        {schemeLogo && <Box css={{ justifySelf: "end" }}>{schemeLogo}</Box>}
      </XYGrid>
    </XYGrid>
  );
};

interface MandateDescriptionProps {
  paymentVariant: PaymentVariant;
  billingRequest: BillingRequestResource;
  billingRequestFlow?: BillingRequestFlowResource;
  extendedConfirmation?: boolean;
  page?: Routes;
}

const MandateDescription = ({
  billingRequest,
  billingRequestFlow,
  extendedConfirmation,
  paymentVariant,
  page,
}: MandateDescriptionProps) => {
  const [showDescription, toggleDescription] = useToggle(false);
  const {
    setError: setAppError,
    setBillingRequest,
    setResidenceCountryMetadata,
  } = useContext(GlobalState);
  const [currencySelectorError, setCurrencySelectorError] = useState<{
    message?: string;
  }>();
  const router = useRouter();

  const chooseCurrencyAction = findAction(
    billingRequest,
    ActionType.ChooseCurrency,
    null
  );

  const showCurrencySelector =
    !billingRequestFlow?.lock_currency &&
    (paymentVariant === PaymentVariant.DirectDebitMandate ||
      paymentVariant === PaymentVariant.VerifiedMandate ||
      paymentVariant === PaymentVariant.VariableRecurringPayments) &&
    chooseCurrencyAction?.available_currencies?.length &&
    chooseCurrencyAction?.available_currencies?.length > 1;

  const hasDirectDebitDescription =
    ((paymentVariant === PaymentVariant.DualFlow ||
      paymentVariant ===
        PaymentVariant.VariableRecurringPaymentsWithFirstPayment) &&
      showDescription) ||
    paymentVariant === PaymentVariant.DirectDebitRestrictedMandate ||
    paymentVariant === PaymentVariant.DirectDebitMandate ||
    paymentVariant === PaymentVariant.VerifiedMandate ||
    paymentVariant === PaymentVariant.VariableRecurringPayments;

  const changeBillingRequestCurrency = useCallback(
    (currency: Currency) => {
      if (!billingRequest?.id) return;
      billingRequestChooseCurrency(billingRequest.id, { currency })
        .then((res) => {
          setBillingRequest(res.billing_requests);
          setCurrencySelectorError({ message: "" });
          if (!res.billing_requests) {
            throw new Error(
              "BillingRequestDescription: billing request missing in response"
            );
          }
          const { mandate_request } = res.billing_requests;
          if (mandate_request && mandate_request.scheme) {
            const newScheme = mandate_request.scheme;
            const collectCustomerAction = findAction(
              res.billing_requests,
              ActionType.CollectCustomerDetails,
              null
            );
            if (collectCustomerAction) {
              const countryCode = collectCustomerAction.collect_customer_details
                ?.default_country_code as CountryCodes;
              const newCustomerFieldsConfig =
                customerDetailsForSchemeAndCountry(
                  newScheme,
                  countryCode,
                  res.billing_requests
                );
              setResidenceCountryMetadata({
                countryCode: countryCode,
                customerFieldsConfig: newCustomerFieldsConfig,
              });
            }
          }
          if (page !== Routes.CollectCustomerDetails) {
            router.push({
              pathname: Routes.CollectCustomerDetails,
              query: router.query,
            });
          }
        })
        .catch(async (errorRes: HTTPError) => {
          if (
            errorRes?.response?.status !== HTTPStatusCode.UnprocessableEntity
          ) {
            showError(
              errorRes,
              setAppError,
              "CustomerDetailsForm",
              ErrorsEnum.ApiError
            );
          } else {
            const errors = await getErrorsFromErrorResponse(errorRes);
            const message = errors[0]?.message;
            setCurrencySelectorError({ message });
          }
        });
    },
    [billingRequest]
  );
  // When extendedConfirmation is requested, we should present the appropriate
  // confirmation message for the scheme, if the scheme provides one.
  if (extendedConfirmation) {
    const extendedConfirmationCopy = translateForScheme({
      scheme: billingRequest?.mandate_request
        ?.scheme as AvailableDebitSchemeEnum,
      translationKey: "confirm-details-page.introduction",
      params: {
        payerName: getPayerName(billingRequest),
        creditorName: billingRequest?.creditor_name,
      },
    });
    // We found an extended confirmation translation, which is only present
    // for schemes that require it. If so, render that.
    if (extendedConfirmationCopy) {
      return (
        <XYGrid rowGap={0.75}>
          <P
            data-testid="mandate-description-extended-confirmation"
            id="mandate-description-extended-confirmation"
            size={2}
            // TODO: confirm with Fraser about color
            color={ColorPreset.TextOnLight_03}
            weight={FontWeight.Normal}
          >
            {extendedConfirmationCopy}
          </P>
        </XYGrid>
      );
    }
  }

  const shouldNotFlex =
    hasDirectDebitDescription &&
    (paymentVariant === PaymentVariant.DirectDebitMandate ||
      paymentVariant === PaymentVariant.VerifiedMandate ||
      paymentVariant === PaymentVariant.VariableRecurringPayments) &&
    showCurrencySelector;
  // Otherwise we understand there to be no scheme requirement, so we render
  // the standard description.

  const displayConsent = !!billingRequest?.mandate_request?.consent_type;

  return (
    <XYGrid rowGap={0.75}>
      <DirectDebitSetupHeader
        paymentVariant={paymentVariant}
        billingRequest={billingRequest}
        toggleDescription={toggleDescription}
        showDescription={showDescription}
      />
      <Box
        layout={shouldNotFlex ? "block" : "flex"}
        alignItems={AlignItems.FlexEnd}
      >
        <Visibility visible={hasDirectDebitDescription ? "block" : "none"}>
          {billingRequest.mandate_request?.description && (
            <P
              data-testid="mandate-request-description"
              id="mandate-request-description"
              size={3}
              weight={FontWeight.SemiBold}
              spaceBelow={0.5}
            >
              {billingRequest.mandate_request?.description}
            </P>
          )}
          <P
            data-testid="mandate-description"
            id="mandate-description"
            size={2}
            // TODO: confirm with Fraser about color
            color={ColorPreset.TextOnLight_03}
            weight={FontWeight.Normal}
          >
            {paymentVariant === PaymentVariant.DirectDebitRestrictedMandate
              ? translateForScheme({
                  scheme: billingRequest?.mandate_request
                    ?.scheme as AvailableDebitSchemeEnum,
                  translationKey:
                    "billing-request-description-info.restricted-direct-debit-mandate",
                  params: { creditor_name: billingRequest.creditor_name },
                })
              : translateForScheme({
                  scheme: billingRequest?.mandate_request
                    ?.scheme as AvailableDebitSchemeEnum,
                  translationKey: "billing-request-description-info",
                  params: {
                    fallen_back: billingRequest.fallback_occurred,
                    creditor_name: billingRequest.creditor_name,
                  },
                })}
          </P>
          {displayConsent && (
            <AuthorisationDetails
              mandateRequest={billingRequest.mandate_request}
            />
          )}
        </Visibility>
        <Visibility
          visible={
            (paymentVariant === PaymentVariant.DirectDebitMandate ||
              paymentVariant === PaymentVariant.VerifiedMandate ||
              paymentVariant === PaymentVariant.VariableRecurringPayments) &&
            !showCurrencySelector
              ? "block"
              : "none"
          }
        >
          {translateForScheme({
            scheme: billingRequest?.mandate_request
              ?.scheme as AvailableDebitSchemeEnum,
            translationKey: "billing-request-description-info-scheme-logo",
            params: {},
          })}
        </Visibility>
        <Visibility
          visible={
            (paymentVariant === PaymentVariant.DirectDebitMandate ||
              paymentVariant === PaymentVariant.VerifiedMandate ||
              paymentVariant === PaymentVariant.VariableRecurringPayments) &&
            showCurrencySelector
              ? "block"
              : "none"
          }
        >
          <Box
            layout="flex"
            alignItems={AlignItems.Center}
            justifyContent={JustifyContent.SpaceBetween}
            spaceAbove={1}
          >
            <Box height={20}>
              {translateForScheme({
                scheme: billingRequest?.mandate_request
                  ?.scheme as AvailableDebitSchemeEnum,
                translationKey: "billing-request-description-info-scheme-logo",
                params: {},
              })}
            </Box>
            {showCurrencySelector && (
              <CurrencySelector
                currencyList={
                  chooseCurrencyAction?.available_currencies as Currency[]
                }
                currenctCurrency={
                  billingRequest.mandate_request?.currency as Currency
                }
                onChange={changeBillingRequestCurrency}
              />
            )}
          </Box>
          {showCurrencySelector &&
            currencySelectorError &&
            currencySelectorError.message && (
              <Hint status={FormFieldStatus.Danger}>
                {" "}
                {currencySelectorError.message}
              </Hint>
            )}
        </Visibility>
      </Box>
    </XYGrid>
  );
};

interface DirectDebitSetupHeaderProps {
  paymentVariant: PaymentVariant | null;
  billingRequest: BillingRequestResource;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleDescription: (nextValue?: any) => void;
  showDescription: boolean;
}
const DirectDebitSetupHeader = ({
  paymentVariant,
  billingRequest,
  toggleDescription,
  showDescription,
}: DirectDebitSetupHeaderProps) => {
  const heading = translateForScheme({
    scheme: billingRequest.mandate_request?.scheme as AvailableDebitSchemeEnum,
    translationKey: "billing-request-description-dual-flow-mandate-heading",
  });
  const logo = translateForScheme({
    scheme: billingRequest?.mandate_request?.scheme as AvailableDebitSchemeEnum,
    translationKey: "billing-request-description-info-scheme-logo",
    params: {},
  });

  switch (paymentVariant) {
    case PaymentVariant.DualFlow:
    case PaymentVariant.VariableRecurringPaymentsWithFirstPayment:
      return (
        <Box layout="flex">
          <P weight={FontWeight.SemiBold}>{heading}</P>
          <Space h={0.75} layout="inline" />

          <Button
            variant={ButtonVariant.InlineUnderlined}
            onClick={toggleDescription}
            aria-controls="mandate-description"
            aria-expanded={showDescription}
            className="fs-unmask"
          >
            <Text size={1} color={ColorPreset.TextOnLight_03}>
              {showDescription ? (
                <Trans id="billing-request-description.mandate-description.show-less-button">
                  Show less
                </Trans>
              ) : (
                <Trans id="billing-request-description.mandate-description.more-details-button">
                  More details
                </Trans>
              )}
            </Text>
          </Button>
          <Space h={0.75} layout="inline" />
          <Box
            layout="flex"
            flexGrow={1}
            justifyContent={JustifyContent.FlexEnd}
          >
            {logo}
          </Box>
        </Box>
      );
    case PaymentVariant.DirectDebitRestrictedMandate:
      return (
        <Box
          layout="flex"
          justifyContent={JustifyContent.SpaceBetween}
          alignItems={AlignItems.Center}
        >
          <P weight={FontWeight.SemiBold}>{heading}</P>

          {/* For some odd reason the Box is not wrapping the exact height of the Image,
          so we have to offset a bit to vertically align it to center */}
          <Box css={{ paddingTop: "6px" }}>{logo}</Box>
        </Box>
      );
    default:
      return null;
  }
};

export default BillingRequestDescription;
