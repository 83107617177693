import {
  Box,
  ButtonSize,
  XYGrid,
  DL,
  AlignItems,
  DLLayout,
  DT,
  DD,
  ButtonVariant,
  Glyph,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import {
  BillingRequestResource,
  BillingRequestFlowResource,
  ActionType,
  BankAuthorisationResource,
} from "@gocardless/api/dashboard/types";
import { isLocked } from "src/components/shared/Router";
import {
  isUsingCompanyName,
  getPayerName,
  getBrandColorFor,
  BrandedComponentType,
  mandateSchemeIs,
  leadBillingRequestScheme,
  isAchMxExperimentMandate,
  getResidenceCountryMetadata,
  isEligibleForRemovePayerName,
} from "src/common/utils";
import BrandedButton from "src/components/shared/BrandedComponents/BrandedButton";
import { PayerThemeType } from "src/state";
import { Scheme } from "src/common/scheme";
import {
  customiseForScheme,
  Scheme as CustomisationScheme,
} from "src/common/schemeCustomisations";

const ConfirmCustomerDetails = ({
  billingRequest,
  billingRequestFlow,
  onEditCustomerDetails,
  payerTheme,
  bankAuthorisation,
}: {
  billingRequest: BillingRequestResource;
  billingRequestFlow: BillingRequestFlowResource;
  onEditCustomerDetails: () => void;
  payerTheme?: PayerThemeType;
  bankAuthorisation?: BankAuthorisationResource;
}) => {
  if (!billingRequest || !billingRequest?.resources?.customer) return null;
  const leadScheme = leadBillingRequestScheme(billingRequest);

  // If we have a company name, then we're a company
  const isCompany = isUsingCompanyName(billingRequest);
  // if the scheme is Autogiro
  const isAutogiroScheme = mandateSchemeIs(billingRequest, Scheme.Autogiro);
  const country_code = getResidenceCountryMetadata({
    billingRequest,
    billingRequestFlow,
  }).countryCode;

  const isCustomerDetailsEditableForScheme = customiseForScheme({
    scheme: leadScheme as CustomisationScheme,
    key: "billing-request.bank-confirm.payment-agreement.editable-customer-details",
    params: { bankAuthorisation, billingRequest, country_code },
  });

  const showCustomerName = !isAchMxExperimentMandate(
    billingRequest,
    country_code
  );

  const isCustomerEditable =
    isCustomerDetailsEditableForScheme &&
    !isLocked(billingRequestFlow, ActionType.CollectCustomerDetails);

  const swedishIdentityNumber =
    billingRequest.resources?.customer_billing_detail?.swedish_identity_number;

  const showPayerNameFields = !isEligibleForRemovePayerName(billingRequest);

  return (
    <XYGrid templateColumns="1fr max-content" alignItems={AlignItems.Baseline}>
      <Box>
        <DL layout={DLLayout.Stack}>
          {showPayerNameFields && showCustomerName && (
            <>
              <DT size={2} data-testid="customer-details-name">
                {isCompany ? (
                  <Trans id="confirm-details-page.customer-details.company-name">
                    Company name
                  </Trans>
                ) : (
                  <Trans id="confirm-details-page.customer-details.name">
                    Name
                  </Trans>
                )}
              </DT>
              <DD size={3}>{getPayerName(billingRequest)}</DD>
            </>
          )}

          <DT size={2}>
            <Trans id="confirm-details-page.customer-details.email">
              Email
            </Trans>
          </DT>
          <DD size={3} css={{ wordBreak: "break-word" }}>
            {billingRequest.resources?.customer?.email}
          </DD>
          {isAutogiroScheme && swedishIdentityNumber && (
            <>
              <DT size={2}>
                {isCompany ? (
                  <Trans id="confirm-details-page.customer-details.swedish-company-number">
                    Swedish Company Number
                  </Trans>
                ) : (
                  <Trans id="confirm-details-page.customer-details.swedish-identity-number">
                    Swedish Identity Number
                  </Trans>
                )}
              </DT>
              <DD size={3}>{swedishIdentityNumber}</DD>
            </>
          )}
        </DL>
      </Box>
      {isCustomerEditable && (
        <BrandedButton
          textColor={getBrandColorFor(BrandedComponentType.Link, payerTheme)}
          variant={ButtonVariant.Inline}
          onClick={onEditCustomerDetails}
          size={ButtonSize.Sm}
          leftIcon={Glyph.Edit}
          id="changeCustomer"
          data-testid="change-customer-button"
        >
          <Trans id="confirm-details-page.form.change-button">Change</Trans>
        </BrandedButton>
      )}
    </XYGrid>
  );
};

export default ConfirmCustomerDetails;
