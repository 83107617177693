import { ToggleButton } from "src/components/shared/ToggleButton";
import { ExpandedStates } from "src/common/states";

interface ToggleDetailsButtonProps {
  setNextState: () => void;
  currentState: ExpandedStates;
  showDetails: boolean;
}

export const ToggleDetailsButton = ({
  setNextState,
  currentState,
  showDetails,
}: ToggleDetailsButtonProps) => {
  return (
    <ToggleButton
      setNextState={setNextState}
      currentState={currentState}
      showDetails={showDetails}
    />
  );
};
