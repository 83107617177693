import {
  AlignItems,
  Box,
  Color,
  FontWeight,
  P,
  Tag,
  TagSize,
  TagVariant,
  XYGrid,
  Space,
  useTheme,
  ColorPreset,
  PlainButton,
  Glyph,
  Text,
  Icon,
  TypePreset,
  MoneyTextVariant,
  MoneyText,
} from "@gocardless/flux-react";
import { t, plural } from "@lingui/macro";
import {
  AvailableDebitSchemeEnum,
  BillingRequestResource,
  Currency,
  InstalmentTemplateResource,
} from "@gocardless/api/dashboard/types";
import { translateForScheme } from "src/common/scheme-translations/translateForScheme";
import { useLingui } from "@lingui/react";
import { useToggle } from "react-use";
import { getTranslatedPaymentPeriodicityMessage } from "src/common/frequency";
import { useI18n } from "src/common/i18n";

import { InstalmentsDetail } from "./InstalmentsDetail";

export interface InstalmentTemplateDescriptionProps {
  instalmentTemplate: InstalmentTemplateResource;
  billingRequest: BillingRequestResource;
}

const InstalmentTemplateDescription = ({
  instalmentTemplate,
  billingRequest,
}: InstalmentTemplateDescriptionProps) => {
  const [locale] = useI18n();
  const [showDetails, toggleDetails] = useToggle(false);
  const { theme } = useTheme();
  const i18n = useLingui();

  const paymentsTotal = (instalmentTemplate.instalments || [])?.reduce(
    (sum, instalment) => Number(instalment.amount) + sum,
    0
  );

  const instalmentNumber = instalmentTemplate.instalments?.length || 0;
  return (
    <>
      <InstalmentsDetail
        isOpen={showDetails}
        onClose={toggleDetails}
        instalmentTemplate={instalmentTemplate}
      />

      <Box data-testid="instalmentTemplate-description">
        <XYGrid rowGap={0.25}>
          <P size={3} weight={FontWeight.SemiBold}>
            {instalmentTemplate?.name}
          </P>
          <Box layout="flex" alignItems={AlignItems.Baseline}>
            <MoneyText
              format="short"
              preset={TypePreset.Body_03}
              weight={FontWeight.SemiBold}
              variant={MoneyTextVariant.Flat}
              amount={paymentsTotal / 100}
              currency={instalmentTemplate.currency || Currency.Gbp}
              locale={locale}
            />
            <Space h={1} layout="inline" />
            <Tag
              size={TagSize.Sm}
              variant={TagVariant.Tinted}
              css={{
                backgroundColor: theme.color(Color.Greystone_300),
                color: theme.color(ColorPreset.TextOnLight_01),
                fontWeight: FontWeight.Normal,
              }}
            >
              Instalment
            </Tag>
          </Box>
          <Box layout="flex" alignItems={AlignItems.FlexEnd}>
            <Box flexGrow={1}>
              <P
                size={1}
                color={ColorPreset.TextOnLight_03}
                weight={FontWeight.Normal}
              >
                {getTranslatedPaymentPeriodicityMessage(instalmentTemplate)}
              </P>
              {instalmentNumber && (
                <P
                  size={1}
                  color={ColorPreset.TextOnLight_03}
                  weight={FontWeight.Normal}
                >
                  <Space v={0.25} />
                  {i18n._(
                    t({
                      id: "instalmentTemplate-description.payment-count-message",
                      comment: "Example: Total of 24 payments",
                      message: `Total of ${plural(instalmentNumber ?? 0, {
                        zero: "# payments",
                        one: "# payment",
                        two: "# payments",
                        few: "# payments",
                        many: "# payments",
                        other: "# payments",
                      })}`,
                    })
                  )}
                </P>
              )}
            </Box>
          </Box>
          <Box layout="flex">
            <Box flexGrow={1}>
              <PlainButton onClick={toggleDetails} type="button">
                <Text preset={TypePreset.Body_01} weight={FontWeight.Bold}>
                  Show details {"  "}
                  <Icon size="11px" name={Glyph.ChevronDown} />
                </Text>
              </PlainButton>
            </Box>
            {translateForScheme({
              scheme: billingRequest?.mandate_request
                ?.scheme as AvailableDebitSchemeEnum,
              translationKey:
                "collect-bank-account-page.bank-details-panel.logo",
              params: {},
            })}
          </Box>
        </XYGrid>
      </Box>
    </>
  );
};

export default InstalmentTemplateDescription;
