import {
  Table,
  Color,
  Text,
  useTheme,
  TypePreset,
  FontWeight,
  MoneyTextVariant,
  MoneyText,
  P,
  Tooltip,
} from "@gocardless/flux-react";
import { convertToLocaleDateString } from "src/common/date-time";
import { useI18n } from "src/common/i18n";
import { Trans } from "@lingui/react";
import {
  Currency,
  InstalmentTemplateResource,
} from "@gocardless/api/dashboard/types";

import { InstalmentsListSummaryHeaderCell } from "./InstalmentsListSummaryHeaderCell";
import { InstalmentsListSummaryCell } from "./InstalmentsListSummaryCell";

export interface InstalmentsDetailTableProps {
  totalAmount: number;
  instalments: InstalmentTemplateResource["instalments"];
  currency: Currency;
}

export const InstalmentsDetailTable = ({
  instalments,
  currency,
  totalAmount,
}: InstalmentsDetailTableProps) => {
  const { theme } = useTheme();
  const [locale] = useI18n();

  return (
    <Table.Container
      css={{
        width: "100%",
        border: "none",
        padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
        backgroundColor: Color.White,
      }}
    >
      <thead>
        <Table.Row>
          <InstalmentsListSummaryHeaderCell noLeftPadding noRightPadding>
            Instalment
          </InstalmentsListSummaryHeaderCell>
          <InstalmentsListSummaryHeaderCell>
            Est. charge date
            <Tooltip
              message={`
                  The charge date will depend on when your customer provides
                  their payment details. Charge dates can be delayed by weekends
                  and holidays.
                `}
            >
              Additional information about estimated charge dates
            </Tooltip>
          </InstalmentsListSummaryHeaderCell>
          <InstalmentsListSummaryHeaderCell noRightPadding alignRight>
            Amount
          </InstalmentsListSummaryHeaderCell>
        </Table.Row>
      </thead>

      <tbody>
        {instalments?.map(({ amount, charge_date: chargeDate }, index) => {
          const isLastRow = index + 1 === instalments.length;
          return (
            <Table.Row key={index.toString()}>
              <InstalmentsListSummaryCell
                paddingBottom={isLastRow}
                noLeftPadding
                noRightPadding
              >
                <P weight={FontWeight.SemiBold}>{index + 1}</P>
              </InstalmentsListSummaryCell>
              <InstalmentsListSummaryCell paddingBottom={isLastRow}>
                {convertToLocaleDateString({
                  date: chargeDate as Date,
                  options: {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  },
                  locale,
                })}
              </InstalmentsListSummaryCell>
              <InstalmentsListSummaryCell
                paddingBottom={isLastRow}
                alignRight
                noRightPadding
              >
                <MoneyText
                  format="short"
                  preset={TypePreset.Body_03}
                  weight={FontWeight.SemiBold}
                  variant={MoneyTextVariant.Flat}
                  amount={Number(amount) / 100}
                  currency={currency}
                  locale={locale}
                />
              </InstalmentsListSummaryCell>
            </Table.Row>
          );
        })}
        <Table.Row>
          <InstalmentsListSummaryCell topBorder noLeftPadding noRightPadding />
          <InstalmentsListSummaryCell topBorder alignRight noRightPadding>
            <Text preset={TypePreset.Body_02} weight={FontWeight.SemiBold}>
              <Trans id="instalment-schedules.instalments-list-summary.total">
                Total:
              </Trans>
            </Text>
          </InstalmentsListSummaryCell>
          <InstalmentsListSummaryCell topBorder alignRight noRightPadding>
            <MoneyText
              format="short"
              preset={TypePreset.Body_03}
              weight={FontWeight.SemiBold}
              variant={MoneyTextVariant.Flat}
              amount={totalAmount / 100}
              currency={currency}
              locale={locale}
            />
          </InstalmentsListSummaryCell>
        </Table.Row>
      </tbody>
    </Table.Container>
  );
};
