import { Currency } from "@gocardless/api/dashboard/types";
import {
  AlignItems,
  Box,
  Color,
  Field,
  FontWeight,
  Label,
  Option,
  Select,
  Text,
  useTheme,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { CurrencySymbolMap } from "src/common/currency";

interface CurrencySelectorProps {
  currencyList: Currency[];
  currenctCurrency?: Currency;
  onChange?: (currency: Currency) => void;
}

export const setCurrentCurrencyFirst = (
  currencyList: Currency[],
  currentCurrency?: Currency
): Currency[] => {
  if (!currentCurrency) {
    return currencyList;
  }
  const filteredList = currencyList.filter(
    (currency: Currency) => currency !== currentCurrency
  );

  return [currentCurrency, ...filteredList];
};

export const currencyOptionText = (currency: Currency) => {
  return `(${CurrencySymbolMap[currency]}) ${currency}`;
};

export const CURRENCY_SELECTOR_NAME = "currencySelector";

const CurrencySelector = ({
  currencyList,
  currenctCurrency,
  onChange,
}: CurrencySelectorProps) => {
  const { theme } = useTheme();
  const list = setCurrentCurrencyFirst(currencyList, currenctCurrency);
  const changeCurrency = (currency: Currency) => onChange && onChange(currency);

  return (
    <Field>
      <Box layout="flex" alignItems={AlignItems.Center}>
        <Label htmlFor={CURRENCY_SELECTOR_NAME}>
          <Text weight={FontWeight.Normal} size={2}>
            <Trans id="billing-request-flow.currency-selector.label-text">
              Pay with
            </Trans>
          </Text>
        </Label>
        <Select
          name={CURRENCY_SELECTOR_NAME}
          defaultValue=""
          id={CURRENCY_SELECTOR_NAME}
          css={{
            boxShadow: "none",
            backgroundColor: theme.color(Color.Greystone_100),
            fontWeight: FontWeight.SemiBold,
            marginLeft: "2px",
            color: theme.color(Color.Greystone_1300),
          }}
          onChange={({ target: { value } }) =>
            changeCurrency(value as Currency)
          }
        >
          {list.map((currency) => (
            <Option value={currency} key={currency}>
              {currencyOptionText(currency)}
            </Option>
          ))}
        </Select>
      </Box>
    </Field>
  );
};

export default CurrencySelector;
