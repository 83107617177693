import { Text, Space, Box } from "@gocardless/flux-react";

const currencyMapping: { [key: string]: string } = {
  GBP: "£",
  EUR: "€",
  USD: "$",
  DKK: "kr.",
  SEK: "kr",
  AUD: "$",
  NZD: "$",
  CAD: "$",
};

const Amount = ({
  currency,
  amountInPence,
  simple,
}: {
  currency: string;
  amountInPence: number;
  simple?: boolean;
}) => {
  const currencySymbol = currencyMapping[currency];
  if (!currencySymbol) {
    throw `currency ${currency} not supported`;
  }

  const poundComponent = Math.floor(amountInPence / 100);
  const penceComponent = (amountInPence % 100).toString().padStart(2, "0");

  if (simple) {
    return (
      <Text>
        {currencySymbol}
        {poundComponent}.{penceComponent}
      </Text>
    );
  }
  return (
    <Box data-testid="amount-container">
      <Text size={5} css={{ lineHeight: "34px" }}>
        {currencySymbol}
      </Text>
      <Space h={0.25} layout="inline" />
      <Text size={7} weight={600}>
        {poundComponent}.
      </Text>
      <Text size={5} css={{ lineHeight: "34px" }}>
        {penceComponent}
      </Text>
    </Box>
  );
};

export default Amount;
